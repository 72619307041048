import React from "react"
import classNames from 'classnames';
import bannerUnderline from "../assets/fancy-underline.svg"

export default function Banner(props) {
  return (
    <section className={`section banner has-text-centered ${props.extraClasses ? props.extraClasses : ""}`} style={{backgroundImage: `url(${props.imageUrl})`}}>
      <div className="container" style={{height: "100%", position:"relative", zIndex:"3"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
          <div>
            <h1 className="title is-1 has-text-white">{props.title}</h1>
            <img className="inline" src={bannerUnderline} alt="underline" />
          </div>
        </div>
      </div>
    </section>
  )
}