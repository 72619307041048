import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"

import swirlIcon from "../assets/about/swirl-icon.svg"

import anneImage from "../assets/about/anne-pitman-bio.jpg"
import firstGalleryImage from "../assets/about/greenery-02.jpg"
import secondGalleryImage from "../assets/about/sunflower.jpg"
import thirdGalleryImage from "../assets/about/winter-01.jpg"
import herWorkImage from "../assets/about/anne-pitman-rolling.jpg"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/about/about-banner.jpg";

  return (
    <Layout title="About Anne" description="A wonderful resource to learning more about who Anne Pitman is and what she's passionate about.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl}/>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <img className="fill inline" src={anneImage} alt="Anne Pitman smiling with two pairs of glasses on" />
              <p class="has-text-weight-bold" style={{ textTransform: 'uppercase', letterSpacing: "2px"}}>Anne Pitman M.Sc., C-IAYT, E-YRT500, <span className="nowrap">Pain Care Certified</span></p>
            </div>
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text1)}} />
              <Link className="button is-secondary half transition" to="/practices">HOME PRACTICES</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-secondary-faded has-text-centered">
        <div className="container" style={{position: "relative"}}> 
          <div className="archway-container">
            <img src={swirlIcon} alt="Swirly icon" style={{width: "100%", height: "100%", opacity:"0.2"}} />
          </div>     
          <h2 className="has-text-secondary title is-2" style={{maxWidth: "900px", margin: "auto", position:"relative", zIndex:"3"}}>
            {frontmatter.bigtext}       
          </h2>
        </div>
      </section>

      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column">
              <img src={firstGalleryImage} alt="Green leaves" className="pink-border" />
            </div>
            <div class="column">
              <img src={secondGalleryImage} alt="Sunflowers" className="pink-border" />
            </div>
            <div class="column">
              <img src={thirdGalleryImage} alt="A little bird sitting on a branch on a snowy day" className="pink-border" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text2)}} />  
            </div>
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text3)}} />  
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-tertiary-faded">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text4)}} /> 
              <div className="buttons">
                <Link className="button is-secondary transition" to="/contact">BOOK A SESSION</Link>
                <Link className="button is-secondary transition" to="/school">BECOME A YOGA THERAPIST</Link>
              </div>
            </div>
            <div className="column">
              <img className="content" src={herWorkImage} alt="Anne stretching on the ground" />
            </div>
          </div>
        </div>
      </section>              
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        text1
        text2
        text3
        text4
        bigtext
      }
    }
  }
`